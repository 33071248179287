import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import {
  useGetUsersByFranchiseIdQuery,
  useSearchUsersQuery,
  useToggleUserActiveStatusMutation,
} from "../../../store/services/userService";
import { useSelector } from "react-redux";
import SubSidebar from "../../../components/SubDistributor/SubSiderbar";
import { useSearchFranchisesQuery, useToggleFranchiseActiveStatusMutation } from '../../../store/services/franchiseService';
import SubHeader from "../../../components/SubDistributor/SubHeader";

const SubKick = () => {
  const [searchName, setSearchName] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const adminId = useSelector((state) => state.authReducer.adminId);
  const [query, setQuery] = useState({ franchiseId: adminId });
  const [toggleFranchiseActiveStatus, { isLoading: isToggleLoading }] =
  useToggleFranchiseActiveStatusMutation();
  const {
    data: searchData = [],
    isLoading: isSearchLoading,
    refetch: refetchSearchData,
  } = useSearchFranchisesQuery(query);

  const handleToggleActiveStatus = async (userId, isActive) => {
    try {
      await toggleFranchiseActiveStatus({ id: userId, isActive }).unwrap();
      await refetchSearchData({ name: searchName, franchiseId: adminId });
    } catch (error) {
      console.error("Error toggling active status:", error);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    try {
      const newQuery = {
        name: searchName,

        franchiseId: adminId,
      };
      setQuery(newQuery);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClear = () => {
    setSearchName("");
  };

  const userData = searchData?.franchises;

  return (
    <div>
      <SubSidebar />
      <div className="content">
      <SubHeader />
        <div className="main-content">
          <div className="card">
            <h3 style={{ borderBottom: "1px solid #0024b3" }}>View Users</h3>
            <div
              className="view"
              style={{
                paddingBottom: "40px",
                backgroundColor: "#e6ebff",
                paddingTop: "20px",
                marginTop: "20px",
              }}
            >
              <div className="row">
                <div className="col-md-3" style={{ marginLeft: "0px" }}>
                  <div className="form">
                    <label htmlFor="searchName">Users Name</label>
                    <input
                      type="text"
                      name="searchName"
                      id="searchName"
                      className="form-control"
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div
                className="suber"
                style={{
                  display: "flex",
                  marginTop: "20px",
                  marginLeft: "10px",
                }}
              >
                <div className="first-main">
                  <button id="submit" onClick={(e) => handleSearch(e)}>
                    Submit
                  </button>
                </div>
                <div className="first-main">
                  <button id="reset" onClick={handleClear}>
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="duimn">
              <h4>
                TOTAL USERS : (
                <span style={{ color: "red" }}>{userData?.length || 0}</span>)
              </h4>
              {hasError && <p>Something went wrong.</p>}
              {isLoading && <p>Loading...</p>}
              <table>
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#e6ebff",
                      textAlign: "center",
                      color: "#0024b3",
                    }}
                  >
                    <th>Player</th>
                    <th>Point</th>
                    <th>Last Login</th>
                    <th>Lock Status</th>
                    <th>Lock by</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userData?.length === 0 && (
                    <tr style={{ textAlign: "center" }}>
                      <td colSpan="7">
                        <span>No data available in table</span>
                      </td>
                    </tr>
                  )}
                  {userData?.map((user) => (
                    <tr key={user.id} style={{ textAlign: "center" }}>
                      <td>{user.name}</td>
                      <td>{user.coins}</td>
                      <td>{user.lastLogin}</td>
                      <td>{user.locked ? "Locked" : "Unlocked"}</td>
                      <td>{user.lockedBy}</td>
                      <td>{user.status ? "Active" : "Inactive"}</td>
                      <td>
                        <button
                          onClick={() =>
                            handleToggleActiveStatus(user._id, !user.status)
                          }
                        >
                          {user.status ? "Deactivate" : "Activate"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubKick;
