import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const settlementService = createApi({
    reducerPath: 'settlement',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://realdeal.org.in/api/api/'
    }),
    endpoints: (builder) => {
        return {
            checkSettlement: builder.query({
                query: (data) => {
                    return {
                        url: 'settle/checkSettlement',
                        method: 'POST',
                        body: data
                    };
                }
            }),
            settleAllDistributors: builder.mutation({
                query: () => {
                    return {
                        url: 'settle/all-distributors',
                        method: 'POST'
                    };
                }
            }),
            settleSubDistributors: builder.mutation({
                query: ({ distributorId, allSettlementId }) => {
                    return {
                        url: `settle/subdistributors/${distributorId}`,
                        method: 'POST',
                        body: { allSettlementId }
                    };
                }
            }),
            settleFranchises: builder.mutation({
                query: ({ subDistributorId, allSettlementId }) => {
                    return {
                        url: `settle/franchises/${subDistributorId}`,
                        method: 'POST',
                        body: { allSettlementId }
                    };
                }
            }),
            getSettlementHistory: builder.query({
                query: (entityId) => {
                    return {
                        url: `settlements/${entityId}`,
                        method: 'GET'
                    }
                }
            })
        };
    }
});

export const {
    useCheckSettlementQuery,
    useSettleAllDistributorsMutation,
    useSettleSubDistributorsMutation,
    useSettleFranchisesMutation,
    useGetSettlementHistoryQuery
} = settlementService;

export default settlementService;
