import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DisSidebar from '../../../components/Distributor/DisSidebar';
import { useChangeDistributorPasswordMutation } from '../../../store/services/distributorService';
import DisHeader from '../../../components/Distributor/DisHeader';

const DisChangepassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const adminId = useSelector((state) => state.authReducer.adminId);
    const [changePassword, response] = useChangeDistributorPasswordMutation();
    
    const handleSubmit = async (type) => {
        try {
          if (!oldPassword || !newPassword || !confirmPassword) {
            setErrorMessage('All fields are mandatory');
            return;
          }
      
          if (newPassword !== confirmPassword) {
            setErrorMessage('New password and confirm password must match');
            return;
          }
      
          // Call the changePassword mutation
          const response = await changePassword({ id: adminId, currentPassword: oldPassword, newPassword, type });
      console.log(response,'main resp')
          // Handle successful password change
          if (response.data) {
            setSuccessMessage(`Password changed successfully (${type} password)`);
            setErrorMessage('');
          } else {
            // Handle mutation errors (assuming response.error has the structure)
            const errorMessage = response.error?.data?.error || 'Error changing password - Unknown error';
            setErrorMessage(errorMessage);
          }
        } catch (error) {
          setErrorMessage('Error changing password - ' + error);
          console.error('Error changing password:', error);
        }
      };

    return (
        <div>
            <DisSidebar />
            <div className="content">
            <DisHeader/>
                <div className="main-content">
                    <div className="card">
                        <h3 style={{ borderBottom: '1px solid #0024b3' }}>Change Password</h3>
                        <div className="view"
                            style={{ paddingBottom: '40px', backgroundColor: '#e6ebff', paddingTop: '20px', marginTop: '20px' }}>
                            <div className="row" style={{ flexWrap: "wrap" }}>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Old Password: *</label>
                                        <input type="password" className="form-control" style={{ marginTop: '5px' }}
                                            placeholder="Old Password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">New Password :</label>
                                        <input type="password" className="form-control" style={{ marginTop: '5px' }}
                                            placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Confirm Password :</label>
                                        <input type="password" className="form-control" style={{ marginTop: '5px' }}
                                            placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                            <div className="suber"
                                style={{ display: 'flex', marginTop: '20px', marginLeft: '14px', justifyContent: 'center' }}>
                                <div className="first-main">
                                    <button id="submit" onClick={() => handleSubmit('login')} >Change Login</button>
                                </div>
                                <div className="first-main">
                                    <button id="submit" onClick={() => handleSubmit('transaction')} >Change Transaction</button>
                                </div>
                                {/* <div className="first-main">
                                    <button id="submit" onClick={() => handleSubmit('report')} disabled={isLoading}>Change Report</button>
                                </div> */}
                                <div className="first-main">
                                    <button id="submit" onClick={() => { setOldPassword(''); setNewPassword(''); setConfirmPassword(''); setErrorMessage(''); setSuccessMessage(''); }}>Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DisChangepassword;
