
import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux';
import { useGetGameHistoryForAdminQuery } from '../../../store/services/gameHistoryService';
import { useCheckSettlementQuery, useGetSettlementHistoryQuery, useSettleFranchisesMutation } from '../../../store/services/settlementService';
import DisSidebar from '../../../components/Distributor/DisSidebar';
import DisHeader from '../../../components/Distributor/DisHeader';
import AdminSidebar from '../../../components/Admin/AdminSidebar';
import AdminHeader from '../../../components/Admin/AdminHeader';

const AdminTurnOver = () => {
    const [username, setusername] = useState('');
    const [startDate, setStartDate] =  useState(new Date().toISOString().split('T')[0]);;
    const [endDate, setEndDate] =  useState(new Date().toISOString().split('T')[0]);;
    const [dateRange, setDateRange] = useState('');
    const adminId = useSelector((state) => state.authReducer.adminId);
    const adminName = useSelector((state) => state.authReducer.adminName);
    const [ queryParams, setQueryParams ] = useState({
        
        fromDate: '',
        toDate: '',

    });



    const { data: filteredTransactions } = useGetGameHistoryForAdminQuery(queryParams);
  
    const handleSubmit = async () => {
        let queryParameters = {};
        
         if (dateRange) {
            const today = new Date();
            let fromDate, toDate;

            switch (dateRange) {
                case 'today':
                    fromDate = toDate = today.toISOString().split('T')[0];
                    break;
                case 'yesterday':
                    const yesterday = new Date(today);
                    yesterday.setDate(today.getDate() - 1);
                    fromDate = toDate = yesterday.toISOString().split('T')[0];
                    break;
                case 'this_week':
                    const monday = new Date(today);
                    monday.setDate(today.getDate() - today.getDay() + 1);
                    fromDate = monday.toISOString().split('T')[0];
                    toDate = today.toISOString().split('T')[0];
                    break;
                case 'last_week':
                    const lastMonday = new Date(today);
                    lastMonday.setDate(today.getDate() - today.getDay() - 6);
                    const lastSunday = new Date(today);
                    lastSunday.setDate(today.getDate() - today.getDay());
                    fromDate = lastMonday.toISOString().split('T')[0];
                    toDate = lastSunday.toISOString().split('T')[0];
                    break;
                case 'this_month':
                    fromDate = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
                    toDate = today.toISOString().split('T')[0];
                    break;
                case 'last_month':
                    const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                    const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                    fromDate = firstDayOfLastMonth.toISOString().split('T')[0];
                    toDate = lastDayOfLastMonth.toISOString().split('T')[0];
                    break;
                default:
                    break;
            }
            queryParameters.fromDate = fromDate;
            queryParameters.toDate = toDate;
        }else if (startDate && endDate) {
            queryParameters.fromDate = startDate;
            queryParameters.toDate = endDate;
        } 

        queryParameters.userName = username;
        setQueryParams(queryParameters);

    };

    let totalPlayPoints = 0;
    let totalWinPoints = 0;
    let totalEndPoints = 0;
    if (filteredTransactions && filteredTransactions.franchiseGameHistory) {
        filteredTransactions.franchiseGameHistory.forEach(transaction => {
            totalPlayPoints += transaction.totalPlayPoints || 0;
            totalWinPoints += transaction.totalWinPoints || 0;
            totalEndPoints += transaction.totalEndPoints || 0;
        });
    }


    
    const { data, error, isLoading } = useGetSettlementHistoryQuery(adminId);
    const { data: settlementstatus } = useCheckSettlementQuery({entityType:"Distributor", entityId:adminId});
  

const [settleSubDistributors, { isLoading:settleloading, error:settleerror, data:settledata }] = useSettleFranchisesMutation();

const handleSettle = async (id) => {
    try {
        await settleSubDistributors({ distributorId:adminId, allSettlementId:id }).unwrap();
        // Handle success (e.g., show a success message or update the UI)
        console.log('Settlement successful', data);
    } catch (err) {
        // Handle error (e.g., show an error message)
        console.error('Failed to settle:', err);
    }
};
  


  return (
    <div>
    <AdminSidebar/>
    <div className="content">
    <AdminHeader/>
    
         <div className="main-content">
 
         <div className="card" style={{height: '100%'}}>
         <h3 style={{borderBottom: '1px solid #0024b3'}}>ADMIN Turn Over Report</h3>
                <div className="view"
                      style={{paddingBottom: '40px',backgroundColor: '#e6ebff',paddingTop: '20px',marginTop: '20px',padding:'10px'}}>
                   <div className="row" style={{flexWrap:"wrap",}}>
                        <div className="col-md-4">
                            <div className="form">
                                <label for="">Games Type:</label><br/>
                                <div className="login-password">
                                    <span><input type="radio" name="" id=""/> Mobile</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form">
                                <label for="">User Id : </label>
                                <input type="text" value={username} onChange={(e) => setusername(e.target.value)} className="form-control" style={{ marginTop: '5px' }} placeholder="User Name" />
                                
                            </div>
                        </div>


                        <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Date Range :</label>
                                        <select value={dateRange} onChange={(e) => setDateRange(e.target.value)} className="form-control" style={{ marginTop: '5px' }}>
                                            <option value="">Select</option>
                                            <option value="today">Today</option>
                                            <option value="yesterday">Yesterday</option>
                                            <option value="this_week">This Week</option>
                                            <option value="last_week">Last Week</option>
                                            <option value="this_month">This Month</option>
                                            <option value="last_month">Last Month</option>
                                        </select>
                                    </div>
                                </div>
                    </div>
                    <div className="row" style={{flexWrap:"wrap"}}>
                    <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Start Date: </label>
                                        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="form-control" style={{ marginTop: '5px' }} placeholder="Transaction Password" />
                                    </div>
                                </div>
                        <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">End Date: </label>
                                        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="form-control" style={{ marginTop: '5px' }} placeholder="Transaction Password" />
                                    </div>
                                </div>

                        <div className="col-md-4">
                            <div className="form">
                                <label for="">Report Password: </label>
                                <input type="password" name="" id="" className="form-control" style={{marginTop: '5px'}}
                                    placeholder="Report Password " />
                            </div>
                        </div>

                    </div>
                    <div className="suber" style={{display: 'flex', marginTop: '20px',marginLeft: '14px'}}>
                    <div className="first-main">
                                    <button id="submit" onClick={handleSubmit}>Submit</button>
                                </div>
                        <div className="first-main">
                            <button id="submit">Clear</button>
                        </div>
                    </div>
                </div>
 
             </div>


{data?.resp == true && settlementstatus?.canSettle == true &&
<>

    <div className="duimn">
                <h5 style={{color:"#0024b3",fontWeight:'bold',marginLeft:'1rem'}}>Settlement</h5>
             <table>
    <thead style={{ backgroundColor: '#e6ebff', textAlign: 'center', color: '#000' }}>
        <tr>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Position</th>
            <th>Settlement Amount</th>
            <th>Action</th>
           
        </tr>
    </thead>
    <tbody>
    {data?.data ? (
                                        data.data.map((item, index) => (
                                            <tr key={index} style={{textAlign:"center"}}>
            <td>{item.fromDate.split("T")[0]}</td>
            <td>{item.toDate.split("T")[0]}</td>
            <td>{item.entityType}</td>
            <td>{item.settledAmount}</td>
            <td>
            <div className="first-main">
                                    <button  onClick={() => handleSettle(item._id)}>Settle</button>
                                </div>
            </td>

            
        </tr>))):null}
    </tbody>
</table>
</div>
</>}
 
             <div className="duimn">
                <h5 style={{color:"#0024b3",fontWeight:'bold',marginLeft:'1rem'}}>Self</h5>
             <table>
    <thead style={{ backgroundColor: '#e6ebff', textAlign: 'center', color: '#000' }}>
        <tr>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Play Points</th>
            <th>Win points</th>
            <th>End points</th>
            <th>Margin</th>
            <th>Net</th>
        </tr>
    </thead>
    <tbody>
        <tr style={{ textAlign: "center" }}>
            <td>{queryParams.fromDate}</td>
            <td>{queryParams.toDate}</td>
            <td>{totalPlayPoints}</td>
            <td>{totalWinPoints}</td>
            <td>{totalEndPoints}</td>
            {/* Calculate Margin and Net */}
            <td>{(totalPlayPoints * 0.01).toFixed(2)}</td>
            <td>{(totalEndPoints + totalPlayPoints * 0.01).toFixed(2)}</td>
        </tr>
    </tbody>
</table>
                    </div>
            <div className="duimn">
                        <table>
                            <thead style={{ backgroundColor: '#e6ebff', textAlign: 'center', color: '#0024b3' }}>
                                <tr>
                                    <th>S. No</th>
                                    <th>User Id</th>
                                    <th>Agent</th>
                                    <th>Play Points</th>
                                    <th>Win Points</th>
                                    <th>End Points</th>
                                    <th>Margin</th>
                                    <th>Net</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredTransactions?.distributorGameHistory ? (
                                        filteredTransactions.distributorGameHistory.map((transaction, index) => (
                                            <tr key={index} style={{textAlign:"center"}}>
                    <td>{index + 1}</td>
                    <td>{transaction.distributorName}</td>
                    <td>ADMIN</td>
                    <td>{transaction.totalPlayPoints}</td>
                    <td>{transaction.totalWinPoints}</td>
                    <td>{transaction.totalEndPoints}</td>
                    <td>{transaction.totalPlayPoints * 0.1}</td>
                    <td>{transaction.totalEndPoints + transaction.totalPlayPoints * 0.1}</td>
                    <td> 
                    <div className="first-main">
                                    <button  onClick={() => {

                                        setTimeout(() => {
                                            alert("Turnover Settled")
                                        }, 2000);
                                    }}>Settle</button>
                                </div></td>
                  </tr>
                                        ))
                                    ) : (
                                      <tr style={{ textAlign: "center" }}>
                                      <td colSpan="10"><span>No data available in table</span></td>
                                  </tr>
                                        )}
                            </tbody>
                        </table>
                    </div>
     </div>
    
 </div>
 </div>
  )
}

export default AdminTurnOver
