import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const gameHistoryService = createApi({
    reducerPath: 'gameHistory',
    tagTypes: 'gameHistory',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://realdeal.org.in/api/api/', // Change this URL to your backend API URL
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.authReducer?.adminToken;
            headers.set('authorization', token ? `Bearer ${token}` : '');
            return headers;
        }
    }),
    endpoints: (builder) => {
        return {
            createGameHistory: builder.mutation({
                query: (data) => ({
                    url: '/games',
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: ['gameHistory']
            }),
            getAllGameHistory: builder.query({
                query: () => '/games',
                providesTags: ['gameHistory']
            }),
            updateGameHistory: builder.mutation({
                query: ({ id, ...data }) => ({
                    url: `/games/${id}`,
                    method: 'PUT',
                    body: data
                }),
                invalidatesTags: ['gameHistory']
            }),
            deleteGameHistory: builder.mutation({
                query: (id) => ({
                    url: `/games/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['gameHistory']
            }),
            getGameHistoryByUserIdAndDateRange: builder.query({
                query: ({ userId, fromDate, toDate, gameType }) => ({
                    url: '/games/filter',
                    method: 'POST',
                    body: { userId, fromDate, toDate, gameType }
                }),
                providesTags: ['gameHistory']
            }),
            getGameHistoryByFranchiseId: builder.query({
                query: ({ franchiseId, fromDate, toDate,userName }) => ({
                    url: `/gamehistory/franchise`,
                    method: 'POST',
                    body: { franchiseId, fromDate, toDate,userName }
                }),
                providesTags: ['gameHistory']
            }),
            getGameHistoryBySubDistributor: builder.query({
                query: ({ subDistributorId, fromDate, toDate, userId, franchiseName }) => ({
                    url: '/gamehistory/subdistributor',
                    method: 'POST',
                    body: { subDistributorId, fromDate, toDate, userId, franchiseName }
                }),
                providesTags: ['gameHistory']
            }),
            getGameHistoryByDistributor: builder.query({
                query: ({ distributorId, fromDate, toDate, userId, subDistributorName }) => ({
                    url: '/gamehistory/distributor',
                    method: 'POST',
                    body: { distributorId, fromDate, toDate, userId, subDistributorName }
                }),
                providesTags: ['gameHistory']
            }),
            getGameHistoryForAdmin: builder.query({
                query: ({ fromDate, toDate }) => ({
                    url: '/gamehistory/admin',
                    method: 'POST',
                    body: { fromDate, toDate }
                }),
                providesTags: ['gameHistory']
            }),
        }
    }
});

export const {
    useCreateGameHistoryMutation,
    useGetAllGameHistoryQuery,
    useUpdateGameHistoryMutation,
    useDeleteGameHistoryMutation,
    useGetGameHistoryByUserIdAndDateRangeQuery,
    useGetGameHistoryByFranchiseIdQuery,
    useGetGameHistoryBySubDistributorQuery,
    useGetGameHistoryByDistributorQuery,
    useGetGameHistoryForAdminQuery
} = gameHistoryService;

export default gameHistoryService;
