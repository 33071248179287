import React, { useEffect, useState } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { useGetTransactionsByUserIdAndDateRangeQuery } from '../../../store/services/transactionService';
import { useSelector } from 'react-redux';
import Header from '../../../components/Header/Header';

const InPoint = () => {
    const [franchiseId, setFranchiseId] = useState('');
        const [receiver, setReceiver] = useState('');
        const [sender, setSender] = useState('');
        const [startDate, setStartDate] = useState('');
        const [endDate, setEndDate] = useState('');
        const [dateRange, setDateRange] = useState('');
        const adminId = useSelector((state) => state.authReducer.adminId);
        const [ queryParams, setQueryParams ] = useState({
          userId: adminId,
            type: 'credit',
            receiver: '',
            sender: '',
            fromDate: '',
            toDate: '',

        });

        const { data: filteredTransactions } = useGetTransactionsByUserIdAndDateRangeQuery(queryParams);
      
        const handleSubmit = async () => {
            let queryParameters = {};
            queryParameters.userId= adminId
            queryParameters.type = 'credit'
    
            if (receiver) queryParameters.receiver = receiver;
            if (sender) queryParameters.sender = sender;
    
            if (startDate && endDate) {
                queryParameters.fromDate = startDate;
                queryParameters.toDate = endDate;
            } else if (dateRange) {
                const today = new Date();
                let fromDate, toDate;
    
                switch (dateRange) {
                    case 'today':
                        fromDate = toDate = today.toISOString().split('T')[0];
                        break;
                    case 'yesterday':
                        const yesterday = new Date(today);
                        yesterday.setDate(today.getDate() - 1);
                        fromDate = toDate = yesterday.toISOString().split('T')[0];
                        break;
                    case 'this_week':
                        const monday = new Date(today);
                        monday.setDate(today.getDate() - today.getDay() + 1);
                        fromDate = monday.toISOString().split('T')[0];
                        toDate = today.toISOString().split('T')[0];
                        break;
                    case 'last_week':
                        const lastMonday = new Date(today);
                        lastMonday.setDate(today.getDate() - today.getDay() - 6);
                        const lastSunday = new Date(today);
                        lastSunday.setDate(today.getDate() - today.getDay());
                        fromDate = lastMonday.toISOString().split('T')[0];
                        toDate = lastSunday.toISOString().split('T')[0];
                        break;
                    case 'this_month':
                        fromDate = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
                        toDate = today.toISOString().split('T')[0];
                        break;
                    case 'last_month':
                        const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                        const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                        fromDate = firstDayOfLastMonth.toISOString().split('T')[0];
                        toDate = lastDayOfLastMonth.toISOString().split('T')[0];
                        break;
                    default:
                        break;
                }
                queryParameters.fromDate = fromDate;
                queryParameters.toDate = toDate;
            }
            setQueryParams(queryParameters);
    
        };
    
    

    return (
        <div>
            <Sidebar />
            <div className="content">
            <Header/>
                <div className="main-content">
                    <div className="card" style={{ height: '100%' }}>
                        <h3 style={{ borderBottom: '1px solid #0024b3' }}>In Points Report</h3>
                        <div className="view" style={{ paddingBottom: '40px', backgroundColor: '#e6ebff', paddingTop: '20px', marginTop: '20px' }}>
                            <div className="row" style={{ flexWrap: "wrap" }}>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Sent By :</label>
                                        <input type="text" value={sender} onChange={(e) => setSender(e.target.value)} className="form-control" style={{ marginTop: '5px' }} placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Start Date: </label>
                                        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="form-control" style={{ marginTop: '5px' }} placeholder="Transaction Password" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">End Date: </label>
                                        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="form-control" style={{ marginTop: '5px' }} placeholder="Transaction Password" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Date Range :</label>
                                        <select value={dateRange} onChange={(e) => setDateRange(e.target.value)} className="form-control" style={{ marginTop: '5px' }}>
                                            <option value="">Select</option>
                                            <option value="today">Today</option>
                                            <option value="yesterday">Yesterday</option>
                                            <option value="this_week">This Week</option>
                                            <option value="last_week">Last Week</option>
                                            <option value="this_month">This Month</option>
                                            <option value="last_month">Last Month</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="suber" style={{ display: 'flex', marginTop: '20px', marginLeft: '14px' }}>
                                <div className="first-main">
                                    <button id="submit" onClick={handleSubmit}>Submit</button>
                                </div>
                                <div className="first-main">
                                    <button id="reset">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="duimn table-responsive">
                        <table>
                            <thead style={{ backgroundColor: '#e6ebff', textAlign: 'center', color: '#0024b3' }}>
                                <tr>
                                    <th>S. No</th>
                                    <th>Date</th>
                                    <th>Receiver</th>
                                    <th>Old Points</th>
                                    <th>In</th>
                                    <th>Out</th>
                                    <th>New Points</th>
                                    <th>Sender</th>
                                    <th>Trans. Id</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredTransactions?.transactions ? (
                                        filteredTransactions.transactions.map((transaction, index) => (
                                            <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{transaction.date}</td>
                    <td>{transaction.receiver}</td>
                    <td>{transaction.oldPoints}</td>
                    <td>{transaction.amount > 0 ? transaction.amount : 'NULL'}</td>
                    <td>{transaction.amount < 0 ? Math.abs(transaction.amount) : 'NULL'}</td>
                    <td>{transaction.newPoints}</td>
                    <td>{transaction.sender}</td>
                    <td>{transaction.transactionId}</td>
                    <td>{transaction.remark}</td>
                  </tr>
                                        ))
                                    ) : (
                                      <tr style={{ textAlign: "center" }}>
                                      <td colSpan="10"><span>No data available in table</span></td>
                                  </tr>
                                        )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InPoint;
