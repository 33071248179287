
import React from 'react'
import logo from "../../assets/images/log.png"
import searchusr from "../../assets/images/search_users.png"
import dashboardimg from "../../assets/images/dashboard.png"
import kickimg from "../../assets/images/kickoff_users.png"
import blncadjust from "../../assets/images/balance_adjustment.png"
import createusrimg from "../../assets/images/create_user.png"
import changepass from "../../assets/images/change_password.png"
import pointfile from "../../assets/images/point_file.png"
import inpoint from "../../assets/images/in_points.png"
import outpoint from "../../assets/images/out_points.png"
import gamehistory from "../../assets/images/game_history.png"
import turnover from "../../assets/images/turn_over.png"
const Sidebar = () => {
  return (
    <div className="sidebar">
    <div className="logo" style={{backgroundColor: "white"}}>
        <center><img src={logo} alt="" style={{width: '80px'}}/></center>
    </div>
    <div className="menu">
        <h2>Main Menu</h2>
    </div>
    <h5 id="headingman">User Management</h5>
    <ul id="geu">
        <li><a href="/fran/home"><span><img src={dashboardimg} alt=""/></span>Dashboard</a></li>

    </ul>
    <h5 id="headingman">User Management</h5>
    <ul id="geu">
        <li><a href="/fran/search"><span><img src={searchusr} alt=""/></span>Search User</a></li>
        <li><a href="/fran/adjustbalance"><span><img src={blncadjust} alt=""/></span>Balance
                Adjustment</a></li>
        <li><a href="/fran/changeuserspass"><span><img src={blncadjust} alt=""/></span>Password
                Adjustment</a></li>
        <li><a href="/fran/kickoff"><span><img src={kickimg} alt=""/></span>Kickoff Users</a></li>
    </ul>
    <h5 id="headingman">Agent Management</h5>
    <ul id="geu">
        <li><a href="/fran/newuser"><span><img src={createusrimg} alt=""/></span>Create User</a></li>
        <li><a href="/fran/changepass"><span><img src={changepass} alt=""/></span>Changes Password</a></li>

    </ul>
    <h5 id="headingman">Reports</h5>
    <ul id="geu">
        <li><a href="/fran/pointfile"><span><img src={pointfile} alt=""/></span>Point File</a> </li>
        <li><a href="/fran/pointin"><span><img src={inpoint} alt=""/></span>In Point</a></li>
        <li><a href="/fran/pointout"><span><img src={outpoint} alt=""/></span>Out Point</a></li>
        <li><a href="/fran/gameHistory"><span><img src={gamehistory} alt=""/></span>GameHistory</a></li>
        <li><a href="/fran/turnover"><span><img src={turnover} alt=""/></span>Turnover</a></li>

    </ul>
</div>
  )
}

export default Sidebar