import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const subdistributionService = createApi({
    reducerPath: 'subdistributors',
    tagTypes: 'subdistributors',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://realdeal.org.in/api/api/',
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.authReducer?.adminToken;
            headers.set('authorization', token ? `Bearer ${token}` : '');
            return headers;
        }
    }),
    endpoints: (builder) => {
        return {
            createSubDistributor: builder.mutation({
                query: (data) => ({
                    url: '/subdistributors',
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: ['subdistributors']
            }),
            changeSubDistributorPassword: builder.mutation({
                query: ({ id, currentPassword, newPassword, type }) => {
                    return {
                        url: `/subdistributors/${id}/password`,
                        method: 'PUT',
                        body: { currentPassword, newPassword, type }
                    }
                },
                invalidatesTags: ['subdistributors']
            }),
            deleteSubDistributor: builder.mutation({
                query: (id) => ({
                    url: `/subdistributors/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['subdistributors']
            }),
            getByDistributorId: builder.query({
                query: (distributorId) => ({
                    url: `/subdistributors/distributor/${distributorId}`,
                    method: 'GET'
                }),
                providesTags: ['subdistributors']
            }),
            getSubDistributorCoins: builder.query({
                query: (id) => ({
                    url: `/subdistributors/${id}/coins`,
                    method: 'GET'
                }),
                providesTags: ['subdistributors']
            }),
            toggleSubDistributorActiveStatus: builder.mutation({
                query: ({ id, isActive }) => ({
                    url: `/subdistributors/${id}/active`,
                    method: 'PUT',
                    body: { isActive }
                }),
                invalidatesTags: ['subdistributors']
            }),
            searchSubDistributors: builder.query({
                query: ({ name, email, lockStatus, status, franchiseId }) => ({
                    url: `/subdistributors/search/${franchiseId}`,
                    method: 'GET',
                    params: { name, email, lockStatus, status }
                }),
                providesTags: ['subdistributors']
            }),
            updateSubDistributorCoins: builder.mutation({
                query: ({ id, action, amount,userId,comment,currentPassword }) => ({
                    url: `/subdistributors/${id}/coins`,
                    method: 'PUT',
                    body: { action, amount,userId,comment,currentPassword }
                }),
                invalidatesTags: ['subdistributors']
            }),
            changeSubDistributorProfitPercentage: builder.mutation({
                query: ({ id, profitPercentage }) => ({
                    url: `/subdistributors/${id}/profitPercentage`,
                    method: 'PUT',
                    body: { profitPercentage }
                }),
                invalidatesTags: ['subdistributors']
            })
        }
    }
});

export const {
    useCreateSubDistributorMutation,
    useChangeSubDistributorPasswordMutation,
    useDeleteSubDistributorMutation,
    useGetByDistributorIdQuery,
    useGetSubDistributorCoinsQuery,
    useToggleSubDistributorActiveStatusMutation,
    useSearchSubDistributorsQuery,
    useUpdateSubDistributorCoinsMutation,
    useChangeSubDistributorProfitPercentageMutation
} = subdistributionService;

export default subdistributionService;
