import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
  Routes,
  BrowserRouter,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Franchise/Home/Home";
import "swiper/css";
import "swiper/css/pagination";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { themeSettings } from "./theme";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import SearchPage from "./pages/Franchise/homePage/searchPage";
import Header from "./components/Header/Header";
import Adjustments from "./pages/Franchise/Adjustments/adjustments";
import Kick from "./pages/Franchise/KickUser/Kick";
import CreateUser from "./pages/Franchise/CreateUser.js/CreateUser";
import Changepassword from "./pages/Franchise/ChangePassword/changepassword";
import PointFile from "./pages/Franchise/PointFile/PointFile";
import InPoint from "./pages/Franchise/InPoints/InPoints";
import OutPoint from "./pages/Franchise/OutPoints/OutPoints";
import GameHistory from "./pages/Franchise/GameHistory/GameHistory";
import TurnOver from "./pages/Franchise/TurnOver/TurnOver";
import SubSearchPage from "./pages/subDistributor/homePage/searchPage";
import SubAdjustments from "./pages/subDistributor/Adjustments/adjustments";
import SubKick from "./pages/subDistributor/KickUser/Kick";
import SubCreateUser from "./pages/subDistributor/CreateUser.js/CreateUser";
import SubChangepassword from "./pages/subDistributor/ChangePassword/changepassword";
import SubPointFile from "./pages/subDistributor/PointFile/PointFile";
import SubInPoint from "./pages/subDistributor/InPoints/InPoints";
import SubOutPoint from "./pages/subDistributor/OutPoints/OutPoints";
import SubGameHistory from "./pages/subDistributor/GameHistory/GameHistory";
import SubTurnOver from "./pages/subDistributor/TurnOver/TurnOver";
import SubHeader from "./components/SubDistributor/SubHeader";
import SubHome from "./pages/subDistributor/Home/Home";
import DisHome from "./pages/Distributor/Home/Home";
import DisSearchPage from "./pages/Distributor/homePage/searchPage";
import DisAdjustments from "./pages/Distributor/Adjustments/adjustments";
import DisKick from "./pages/Distributor/KickUser/Kick";
import DisCreateUser from "./pages/Distributor/CreateUser.js/CreateUser";
import DisChangepassword from "./pages/Distributor/ChangePassword/changepassword";
import DisPointFile from "./pages/Distributor/PointFile/PointFile";
import DisInPoint from "./pages/Distributor/InPoints/InPoints";
import DisOutPoint from "./pages/Distributor/OutPoints/OutPoints";
import DisHeader from "./components/Distributor/DisHeader";
import FranLogin from "./pages/Franchise/Login/login";
import SubLogin from "./pages/subDistributor/Login/login";
import DisLogin from "./pages/Distributor/Login/login";
import DisGameHistory from "./pages/Distributor/GameHistory/GameHistory";
import DisTurnOver from "./pages/Distributor/TurnOver/TurnOver";
import ChangeUsersPass from "./pages/Franchise/ChangeUsersPass/changeuserspass";
import ChangeFranchisePass from "./pages/subDistributor/ChangeFranchisePass/ChangeFranchisePass";
import AllDisSettlement from "./pages/AllDistributorsetllement";
import AdminLogin from "./pages/Admin/Login/login";
import AdminHome from "./pages/Admin/Home/Home";
import AdminSearchPage from "./pages/Admin/homePage/searchPage";
import AdminAdjustments from "./pages/Admin/Adjustments/adjustments";
import AdminKick from "./pages/Admin/KickUser/Kick";
import AdminCreateUser from "./pages/Admin/CreateUser.js/CreateUser";
import AdminPointFile from "./pages/Admin/PointFile/PointFile";
import AdminInPoint from "./pages/Admin/InPoints/InPoints";
import AdminOutPoint from "./pages/Admin/OutPoints/OutPoints";
import AdminGameHistory from "./pages/Admin/GameHistory/GameHistory";
import AdminTurnOver from "./pages/Admin/TurnOver/TurnOver";

const Layout = () => {
  return (
    <div>
      <Header />
      <Outlet />
    </div>
  );
};
const SubLayout = () => {
  return (
    <div>
      <SubHeader />
      <Outlet />
    </div>
  );
};
const DisLayout = () => {
  return (
    <div>
      <DisHeader />
      <Outlet />
    </div>
  );
};
const App = () =>{
  const mode = useSelector((state) => state.authReducer.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.authReducer.adminToken));
  console.log(isAuth)
  return(
    <BrowserRouter>
            <ThemeProvider theme={theme}>
          <CssBaseline />
    <Routes>
    <Route path="/mainadmin" >
       <Route index element={isAuth ?  <Navigate to="/mainadmin/home" />:<AdminLogin />} />
       <Route path="/mainadmin/all-turnover" element={<AllDisSettlement />} />
                <Route path="/mainadmin/home" element={isAuth ? <AdminHome />: <Navigate to="/mainadmin" />} />
                <Route path="/mainadmin/search" element={<AdminSearchPage />} />
                <Route path="/mainadmin/adjustbalance" element={<AdminAdjustments />} />
                <Route path="/mainadmin/kickoff" element={<AdminKick />} />
                <Route path="/mainadmin/newuser" element={<AdminCreateUser />} />
                
                <Route path="/mainadmin/pointfile" element={<AdminPointFile />} />
                <Route path="/mainadmin/pointin" element={<AdminInPoint />} />
                <Route path="/mainadmin/pointout" element={<AdminOutPoint />} />
                <Route path="/mainadmin/gameHistory" element={<AdminGameHistory />} />
                <Route path="/mainadmin/turnover" element={<AdminTurnOver />} />
             </Route>
    <Route path="/fran" >
       <Route index element={isAuth ?  <Navigate to="/fran/home" />:<FranLogin />} />
                <Route path="/fran/home" element={isAuth ? <Home />: <Navigate to="/fran" />} />
                <Route path="/fran/search" element={<SearchPage />} />
                <Route path="/fran/adjustbalance" element={<Adjustments />} />
                <Route path="/fran/changeuserspass" element={<ChangeUsersPass />} />
                <Route path="/fran/kickoff" element={<Kick />} />
                <Route path="/fran/newuser" element={<CreateUser />} />
                <Route path="/fran/changepass" element={<Changepassword />} />
                <Route path="/fran/pointfile" element={<PointFile />} />
                <Route path="/fran/pointin" element={<InPoint />} />
                <Route path="/fran/pointout" element={<OutPoint />} />
                <Route path="/fran/gameHistory" element={<GameHistory />} />
                <Route path="/fran/turnover" element={<TurnOver />} />
             </Route>
    <Route path="/sd" >
    <Route index element={<SubLogin />} />
       
       <Route path="/sd/home" element={<SubHome />} />
       <Route path="/sd/search" element={<SubSearchPage />} />
       <Route path="/sd/adjustbalance" element={<SubAdjustments />} />
       <Route path="/sd/changefranpass" element={<ChangeFranchisePass />} />
       <Route path="/sd/kickoff" element={<SubKick />} />
       <Route path="/sd/newuser" element={<SubCreateUser />} />
       <Route path="/sd/changepass" element={<SubChangepassword />} />
       <Route path="/sd/pointfile" element={<SubPointFile />} />
       <Route path="/sd/pointin" element={<SubInPoint />} />
       <Route path="/sd/pointout" element={<SubOutPoint />} />
       <Route path="/sd/gameHistory" element={<SubGameHistory />} />
       <Route path="/sd/turnover" element={<SubTurnOver />} />

       </Route>

       <Route path="/dist">
       <Route index element={<DisLogin />} />
       <Route path="/dist/home" element={<DisHome />} />
       <Route path="/dist/search" element={<DisSearchPage />} />
       <Route path="/dist/adjustbalance" element={<DisAdjustments />} />
       <Route path="/dist/kickoff" element={<DisKick />} />
       <Route path="/dist/newuser" element={<DisCreateUser />} />
       <Route path="/dist/changepass" element={<DisChangepassword />} />
       <Route path="/dist/pointfile" element={<DisPointFile />} />
       <Route path="/dist/pointin" element={<DisInPoint />} />
       <Route path="/dist/pointout" element={<DisOutPoint />} />
       <Route path="/dist/gameHistory" element={<DisGameHistory />} />
       <Route path="/dist/turnover" element={<DisTurnOver />} />

       </Route>

       
   </Routes></ThemeProvider>
   </BrowserRouter>
  )
};

// function App() {
//   return (
//     <div className="font-bodyFont">
//       <RouterProvider router={router} />
//     </div>
//   );
// }

export default App;
