import React, { useEffect, useState } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { useGetTransactionsByFranchiseIdQuery, useGetTransactionsByUserIdAndDateRangeQuery } from '../../../store/services/transactionService';
import { useSelector } from 'react-redux';
import SubSidebar from '../../../components/SubDistributor/SubSiderbar';
import SubHeader from '../../../components/SubDistributor/SubHeader';


    const SubPointFile = () => {
        const [franchiseId, setFranchiseId] = useState('');
        const [receiver, setReceiver] = useState('');
        const [sender, setSender] = useState('');
        const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]); // Set to today's date
        const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]); // Set to today's date
         const [dateRange, setDateRange] = useState('');
        const adminId = useSelector((state) => state.authReducer.adminId);
        const [ queryParams, setQueryParams ] = useState({
          userId: adminId,
            receiver: '',
            sender: '',
            fromDate: '',
            toDate: '',

        });

        const { data: filteredTransactions } = useGetTransactionsByUserIdAndDateRangeQuery(queryParams);
      
        const handleSubmit = async (e) => {
          e.preventDefault();
          console.log('ok api hit')
            let queryParameters = {};
            queryParameters.userId= adminId
    
            if (receiver) queryParameters.receiver = receiver;
            if (sender) queryParameters.sender = sender;
    
            if (dateRange) {
                const today = new Date();
                let fromDate, toDate;
    
                switch (dateRange) {
                    case 'today':
                        fromDate = toDate = today.toISOString().split('T')[0];
                        break;
                    case 'yesterday':
                        const yesterday = new Date(today);
                        yesterday.setDate(today.getDate() - 1);
                        fromDate = toDate = yesterday.toISOString().split('T')[0];
                        break;
                    case 'this_week':
                        const monday = new Date(today);
                        monday.setDate(today.getDate() - today.getDay() + 1);
                        fromDate = monday.toISOString().split('T')[0];
                        toDate = today.toISOString().split('T')[0];
                        break;
                    case 'last_week':
                        const lastMonday = new Date(today);
                        lastMonday.setDate(today.getDate() - today.getDay() - 6);
                        const lastSunday = new Date(today);
                        lastSunday.setDate(today.getDate() - today.getDay());
                        fromDate = lastMonday.toISOString().split('T')[0];
                        toDate = lastSunday.toISOString().split('T')[0];
                        break;
                    case 'this_month':
                        fromDate = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
                        toDate = today.toISOString().split('T')[0];
                        break;
                    case 'last_month':
                        const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                        const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                        fromDate = firstDayOfLastMonth.toISOString().split('T')[0];
                        toDate = lastDayOfLastMonth.toISOString().split('T')[0];
                        break;
                    default:
                        break;
                }
                queryParameters.fromDate = fromDate;
                queryParameters.toDate = toDate;
            }else  if (startDate && endDate) {
              queryParameters.fromDate = startDate;
              queryParameters.toDate = endDate;
          } 
            console.log('ok query')
            setQueryParams(queryParameters);
    
        };
    
    
    return (
        <div>
          <SubSidebar />
          <div class="content">
          <SubHeader />
            <div class="main-content">
              <div class="card" style={{ height: '100%' }}>
                <h3 style={{ borderBottom: '1px solid #0024b3' }}>Ledger</h3>
                <div class="view" style={{ paddingBottom: '40px', backgroundColor: '#e6ebff', paddingTop: '20px', marginTop: '20px' }}>
                  <div className="row" style={{ flexWrap: "wrap" }}>
                    
                    <div class="col-md-4">
                      <div class="form">
                        <label for="">Receive By:</label>
                        <input type="text" value={receiver} onChange={(e) => setReceiver(e.target.value)} class="form-control" style={{ marginTop: '5px' }} placeholder="Username" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form">
                        <label for="">Sent By:</label>
                        <input type="text" value={sender} onChange={(e) => setSender(e.target.value)} class="form-control" style={{ marginTop: '5px' }} placeholder="Password" />
                      </div>
                    </div>
                  </div>
                  <div class="row" style={{ flexWrap: "wrap" }}>
                    <div class="col-md-4">
                      <div class="form">
                        <label for="">Start Date: </label>
                        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} class="form-control" style={{ marginTop: '5px' }} placeholder="Transaction Password" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form">
                        <label for="">End Date: </label>
                        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} class="form-control" style={{ marginTop: '5px' }} placeholder="Transaction Password" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form">
                        <label for="">Date Range :</label>
                        <select value={dateRange} onChange={(e) => setDateRange(e.target.value)} class="form-control" style={{ marginTop: '5px' }}>
                          <option value="">Select</option>
                          <option value="today">Today</option>
                          <option value="yesterday">Yesterday</option>
                          <option value="this_week">This Week</option>
                          <option value="last_week">Last Week</option>
                          <option value="this_month">This Month</option>
                          <option value="last_month">Last Month</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="suber" style={{ display: 'flex', marginTop: '20px', marginLeft: '14px' }}>
                    <div class="first-main">
                      <button id="submit" onClick={handleSubmit}>Submit</button>
                    </div>
                    <div class="first-main">
                  <button id="reset">Clear</button>
                </div>
                    </div>
                
              </div>
        
            <div className="duimn">
            <table>
                <tr style={{ backgroundColor: '#e6ebff', textAlign: 'center', color: '#0024b3' }}>
                  <th>S. No</th>
                  <th>Date</th>
                  <th>Reciever</th>
                  <th>Old Points</th>
                  <th>In</th>
                  <th>Out </th>
                  <th>New Points</th>
                  <th>Sender</th>
                  <th>Trans. Id</th>
                  <th>Comments</th>
                </tr>

                {filteredTransactions?.transactions ? (
                                        filteredTransactions.transactions.map((transaction, index) => (
                                            <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{transaction.date}</td>
                    <td>{transaction.receiver}</td>
                    <td>{transaction.oldPoints}</td>
                    <td>{transaction.transactionType == 'credit' ? transaction.amount : 0}</td>
                    <td>{transaction.transactionType == 'debit' ? transaction.amount : 0}</td>
                    <td>{transaction.newPoints}</td>
                    <td>{transaction.sender}</td>
                    <td>{transaction.transactionId}</td>
                    <td>{transaction.remark}</td>
                  </tr>
                                        ))
                                    ) : (
                                      <tr style={{ textAlign: "center" }}>
                                      <td colSpan="10"><span>No data available in table</span></td>
                                  </tr>
                                        )}
                
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubPointFile;
