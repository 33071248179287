import React, { useEffect, useState } from "react";
import { IconButton, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DarkMode, LightMode } from "@mui/icons-material";
import { useFranchiseLoginMutation } from "../../store/services/authService";
import { logout, setAdminId, setAdminToken } from "../../store/reducers/authReducer";
import { useNavigate } from "react-router-dom";

const Header = () => {
    const adminName = useSelector((state) => state.authReducer.adminName);
    const adminCoins = useSelector((state) => state.authReducer.admincoins);
    const adminProfit = useSelector((state) => state.authReducer.admin_profit_percent);
    const navigate = useNavigate();
    const dispatch = useDispatch();
  return (
    <div className="upper-section">
    <div className="first-main" style={{display: 'flex'}}>
        <h5>Welcome</h5>
        <span>{adminName}</span>
    </div>
    <div className="first-main" style={{display: 'flex'}}>
        <h5>Balance : </h5>
        <span>{adminCoins}</span>
    </div>
    <div className="first-main" style={{display: 'flex'}}>
        <h5>Profit Percentage : </h5>
        <span>{adminProfit} %</span>
    </div>
    <div className="first-main" style={{display: 'flex'}}>
        <span>13-03-2024 17:36:31</span>
    </div>
    <div className="first-main" style={{display: 'flex'}}>
        <button onClick={() => {dispatch(logout('admin-token')); navigate('/fran/');}}>LOGOUT</button>
    </div>

</div>
  );
};

export default Header;
