import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { useCreateUserMutation } from '../../../store/services/userService';
import { useSelector } from 'react-redux';
import Header from '../../../components/Header/Header';

const CreateUser = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [transactionPassword, setTransactionPassword] = useState('');
    const [eligibleGames, setEligibleGames] = useState('');
    const adminId = useSelector((state) => state.authReducer.adminId);
    // Mutation hook for creating a user
    const [createUser, { isLoading: isCreatingUser }] = useCreateUserMutation();

    const handleSubmit = async () => {
        try {
            // Call the createUser mutation
            await createUser({
                name: username,
                email: mobileNumber, // Assuming email is same as username
                password,
                transactionpassword: transactionPassword,
                franchise: adminId, // Set your franchise ID here
                // Add more fields as needed
            });
            // Clear the form after successful creation
            resetForm();
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };

    const resetForm = () => {
        // Reset all state variables to their initial values
        setUsername('');
        setPassword('');
        setMobileNumber('');
        setTransactionPassword('');
        setEligibleGames('');
    };

    return (
        <div>
            <Sidebar />
            <div className="content">
            <Header/>
                <div className="main-content">
                    <div className="card">
                        <h3 style={{ borderBottom: '1px solid #0024b3' }}>User - General Information</h3>
                        <div className="view"
                            style={{ paddingBottom: '40px', backgroundColor: '#e6ebff', paddingTop: '20px', marginTop: '20px' }}>
                            <div className="row" style={{ flexWrap: 'wrap' }}>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">User Name :</label>
                                        <input type="text" className="form-control" style={{ marginTop: '5px' }}
                                            placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Password :</label>
                                        <input type="password" className="form-control" style={{ marginTop: '5px' }}
                                            placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Username :</label>
                                        <input type="text" className="form-control" style={{ marginTop: '5px' }}
                                            placeholder="Enter Username to Login" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Agent Transaction Password :</label>
                                        <input type="password" className="form-control" style={{ marginTop: '5px' }}
                                            placeholder="Transaction Password" value={transactionPassword} onChange={(e) => setTransactionPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Eligible Games</label>
                                        <select className="form-control" style={{ marginTop: '5px' }} value={eligibleGames} onChange={(e) => setEligibleGames(e.target.value)}>
                                            <option value="">Select</option>
                                            <option value="game1">Game 1</option>
                                            {/* Add more options as needed */}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="suber" style={{ display: 'flex', marginTop: '20px', marginLeft: '14px' }}>
                                <div className="first-main">
                                    <button id="submit" onClick={handleSubmit} disabled={isCreatingUser}>Submit</button>
                                </div>
                                <div className="first-main">
                                    <button id="reset" onClick={resetForm} disabled={isCreatingUser}>Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateUser;
