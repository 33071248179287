import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const distributorService = createApi({
    reducerPath: 'distributors',
    tagTypes: 'distributors',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://realdeal.org.in/api/api/',
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.authReducer?.adminToken;
            headers.set('authorization', token ? `Bearer ${token}` : '');
            return headers;
        }
    }),
    endpoints: (builder) => {
        return {
            createDistributor: builder.mutation({
                query: (data) => ({
                    url: '/distributors',
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: ['distributors']
            }),
            changeDistributorPassword: builder.mutation({
                query: ({ id, currentPassword, newPassword, type }) => {
                    return {
                        url: `/distributors/${id}/password`,
                        method: 'PUT',
                        body: { currentPassword, newPassword, type }
                    }
                },
                invalidatesTags: ['distributors']
            }),
            deleteDistributor: builder.mutation({
                query: (id) => ({
                    url: `/distributors/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['distributors']
            }),
            getSubDistributorsByDistributorId: builder.query({
                query: (distributorId) => ({
                    url: `/distributors/${distributorId}/subdistributors`,
                    method: 'GET'
                }),
                providesTags: ['distributors']
            }),
            getDistributorCoins: builder.query({
                query: (id) => ({
                    url: `/distributors/${id}/coins`,
                    method: 'GET'
                }),
                providesTags: ['distributors']
            }),
            toggleDistributorActiveStatus: builder.mutation({
                query: ({ id, isActive }) => ({
                    url: `/distributors/${id}/active`,
                    method: 'PUT',
                    body: { isActive }
                }),
                invalidatesTags: ['distributors']
            }),
            searchDistributors: builder.query({
                query: ({ name, email }) => ({
                    url: `/distributors/search`,
                    method: 'GET',
                    params: { name, email }
                }),
                providesTags: ['distributors']
            }),
            updateDistributorCoins: builder.mutation({
                query: ({ id, action, amount }) => ({
                    url: `/distributors/${id}/coins`,
                    method: 'PUT',
                    body: { action, amount }
                }),
                invalidatesTags: ['distributors']
            }),
            changeDistributorProfitPercentage: builder.mutation({
                query: ({ id, profitPercentage }) => ({
                    url: `/distributors/${id}/profitPercentage`,
                    method: 'PUT',
                    body: { profitPercentage }
                }),
                invalidatesTags: ['distributors']
            })
        }
    }
});

export const {
    useCreateDistributorMutation,
    useChangeDistributorPasswordMutation,
    useDeleteDistributorMutation,
    useGetSubDistributorsByDistributorIdQuery,
    useGetDistributorCoinsQuery,
    useToggleDistributorActiveStatusMutation,
    useSearchDistributorsQuery,
    useUpdateDistributorCoinsMutation,
    useChangeDistributorProfitPercentageMutation
} = distributorService;

export default distributorService;
