import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { useCreateUserMutation } from '../../../store/services/userService';
import { useCreateFranchiseMutation } from '../../../store/services/franchiseService';
import { useSelector } from 'react-redux';
import SubSidebar from '../../../components/SubDistributor/SubSiderbar';
import SubHeader from '../../../components/SubDistributor/SubHeader';

const SubCreateUser = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [profitPercentage, setprofitPercentage] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [transactionPassword, setTransactionPassword] = useState('');
    const [eligibleGames, setEligibleGames] = useState('');
    const adminId = useSelector((state) => state.authReducer.adminId);
    // Mutation hook for creating a user
    const [createFranchise, { isLoading: isCreatingUser }] = useCreateFranchiseMutation();

    const handleSubmit = async () => {
        try {
            // Call the createFranchise mutation
            await createFranchise({
                name: username,
                email: mobileNumber, // Assuming email is same as username
                password,
                transactionpassword: transactionPassword,
                profitPercentage: profitPercentage, // Set your franchise ID here
                subDistributor: adminId, // Set your franchise ID here
                // Add more fields as needed
            });
            // Clear the form after successful creation
            resetForm();
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };

    const resetForm = () => {
        // Reset all state variables to their initial values
        setUsername('');
        setPassword('');
        setMobileNumber('');
        setTransactionPassword('');
        setEligibleGames('');
    };

    return (
        <div>
            <SubSidebar />
            <div className="content">
            <SubHeader />
                <div className="main-content">
                    <div className="card">
                        <h3 style={{ borderBottom: '1px solid #0024b3' }}>Franchise - General Information</h3>
                        <div className="view"
                            style={{ paddingBottom: '40px', backgroundColor: '#e6ebff', paddingTop: '20px', marginTop: '20px' }}>
                            <div className="row" style={{ flexWrap: 'wrap' }}>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Franchise Name :</label>
                                        <input type="text" className="form-control" style={{ marginTop: '5px' }}
                                            placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Password :</label>
                                        <input type="password" className="form-control" style={{ marginTop: '5px' }}
                                            placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Username :</label>
                                        <input type="text" className="form-control" style={{ marginTop: '5px' }}
                                            placeholder="Enter Username to Login" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Franchise Transaction Password :</label>
                                        <input type="password" className="form-control" style={{ marginTop: '5px' }}
                                            placeholder="Transaction Password" value={transactionPassword} onChange={(e) => setTransactionPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Profit Percentage :</label>
                                        <input type="number" className="form-control" style={{ marginTop: '5px' }}
                                            placeholder="Profit Percentage" value={profitPercentage} onChange={(e) => setprofitPercentage(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="suber" style={{ display: 'flex', marginTop: '20px', marginLeft: '14px' }}>
                                <div className="first-main">
                                    <button id="submit" onClick={handleSubmit} disabled={isCreatingUser}>Submit</button>
                                </div>
                                <div className="first-main">
                                    <button id="reset" onClick={resetForm} disabled={isCreatingUser}>Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubCreateUser;
