import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import SubSidebar from "../../../components/SubDistributor/SubSiderbar";
import { useSearchFranchisesQuery } from "../../../store/services/franchiseService";
import { useDispatch, useSelector } from "react-redux";
import DisSidebar from "../../../components/Distributor/DisSidebar";
import { useSearchSubDistributorsQuery } from "../../../store/services/subdistributionService";
import { useNavigate, useNavigation } from "react-router-dom";
import { useFranchiseLoginMutation } from "../../../store/services/authService";
import { setAdminCoins, setAdminId, setAdminName, setAdminProfit, setAdminToken } from "../../../store/reducers/authReducer";

const FranLogin = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [captcha, setCaptcha] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [login, response] = useFranchiseLoginMutation();
    const errors = response?.error?.data?.errors ? response?.error?.data?.errors : [];
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const loginData = { email:username, password:password };
            await login(loginData)
        } catch (error) {
            console.error("An error occurred during login:", error);
            // Handle network error or other unexpected errors
        }
    };
    useEffect(() => {
        if(response.isSuccess) {
            localStorage.setItem('admin-token', response?.data?.token);
            localStorage.setItem('admin-id', response?.data?.id);
            dispatch(setAdminToken(response?.data?.token));
            dispatch(setAdminProfit(response?.data?.profit));
            dispatch(setAdminName(response?.data?.name));
            dispatch(setAdminCoins(response?.data?.coins));
            dispatch(setAdminId(response?.data?.id));
            navigate('/fran/home');
        }
    }, [response.isSuccess])

  return (
 
    <div className="login">
    <div className="logo">
        <a href="index.php">
            <img src="img/log.png" alt="logo" />
        </a>
    </div>
    <div className="content d-none d-md-block">
        
        <form onSubmit={handleSubmit} autocomplete="off">
            <h3 className="form-title" style={{marginBottom: '30px'}}>Login to your account</h3>
            <div className="form-group" style={{marginTop: '10px'}}>
                <div className="input-icon">
                    <i className="fa fa-user"></i>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Username"
                        name="username"
                        required
                        className="form-control placeholder-no-fix"
                    />
                </div>
            </div>
            <div className="form-group" style={{marginTop: '20px'}}>
                <div className="input-icon">
                    <i className="fa fa-lock"></i>
                    <input className="form-control placeholder-no-fix" type="password" placeholder="Password"
                        name="password"  value={password}
                        onChange={(e) => setPassword(e.target.value)}/>
                </div>
            </div>
            {/* <div className="form-group" style={{marginTop: '20px'}}>
                <div className="input-icon">
                    <i className="fa fa-lock"></i>
                    <input type="text" className="form-control placeholder-no-fix" placeholder="Enter Captcha" name="code"
                        autocomplete="off" required/>
                </div>
            </div> */}
            <div className="form-group" id="duiman">
                <div className="fisrt">
                    <label className="checkbox" style={{fontSize: '13px'}}>
                        <input type="checkbox" name="remember" value="1"/> Remember me </label>
                </div>
                <div className="fisrt">
                <button type="submit" onClick={handleSubmit}>LOGIN</button>
                    {/* <input type="submit" className="btn green-haze pull-right" name="actionLogin" value="LOGIN"></input> */}
                </div>
            </div>
        </form>
    </div>

</div>
 


  );
};

export default FranLogin;
