import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const userService = createApi({
    reducerPath: 'users',
    tagTypes: 'users',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://realdeal.org.in/api/api/',
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.authReducer?.adminToken;
            headers.set('authorization', token ? `Bearer ${token}` : '');
            return headers;
        }
    }),
    endpoints: (builder) => {
        return {
            createUser: builder.mutation({
                query: (data) => ({
                    url: '/users',
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: ['users']
            }),
            changeUserPassword: builder.mutation({
                query: ({ id, currentPassword, newPassword }) => ({
                    url: `/users/${id}/password`,
                    method: 'PUT',
                    body: { currentPassword, newPassword }
                }),
                invalidatesTags: ['users']
            }),
            deleteUser: builder.mutation({
                query: (id) => ({
                    url: `/users/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['users']
            }),
            getUserCoins: builder.query({
                query: (id) => ({
                    url: `/users/${id}/coins`,
                    method: 'GET'
                }),
                providesTags: ['users']
            }),
            toggleUserActiveStatus: builder.mutation({
                query: ({ id, isActive }) => ({
                    url: `/users/${id}/active`,
                    method: 'PUT',
                    body: { isActive }
                }),
                invalidatesTags: ['users']
            }),
            searchUsers: builder.query({
                query: ({ name, email, lockStatus, status, franchiseId }) => ({
                    url: `/users/search/${franchiseId}`, // Include franchiseId in the URL
                    method: 'GET',
                    params: { name, email, lockStatus, status } // Include additional parameters
                }),
                providesTags: ['users']
            }),            
            updateUserCoins: builder.mutation({
                query: ({ franchiseId, action, amount,userId,comment,currentPassword }) => ({
                    url: `/users/${franchiseId}/coins`,
                    method: 'PUT',
                    body: { action, amount,userId,comment,currentPassword }
                }),
                invalidatesTags: ['users']
            }),
            updateAdminUserPass: builder.mutation({
                query: ({ franchiseId,userId, newPassword }) => ({
                    url: `/users/${franchiseId}/updatepass`,
                    method: 'PUT',
                    body: { newPassword ,userId}
                }),
                invalidatesTags: ['users']
            }),
            getUsersByFranchiseId: builder.query({
                query: (franchiseId) => ({
                    url: `/users/franchise/${franchiseId}`,
                    method: 'GET'
                }),
                providesTags: ['users']
            })
        }
    }
});

export const {
    useCreateUserMutation,
    useChangeUserPasswordMutation,
    useDeleteUserMutation,
    useGetUserCoinsQuery,
    useToggleUserActiveStatusMutation,
    useSearchUsersQuery,
    useUpdateUserCoinsMutation,
    useUpdateAdminUserPassMutation,
    useGetUsersByFranchiseIdQuery
} = userService;

export default userService;
