import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import SubSidebar from "../../../components/SubDistributor/SubSiderbar";
import { useSearchFranchisesQuery } from "../../../store/services/franchiseService";
import { useSelector } from "react-redux";
import SubHeader from "../../../components/SubDistributor/SubHeader";

const SubHome = () => {
    const [activeFranchises, setActiveFranchises] = useState(0);
  const [inactiveFranchises, setInactiveFranchises] = useState(0);
  const [suspendedFranchises, setSuspendedFranchises] = useState(0);
  const adminId = useSelector((state) => state.authReducer.adminId);
  const [query, setQuery] = useState({franchiseId: adminId});

  const { data: searchData = [], isLoading: isSearchLoading, refetch: refetchSearchData } = useSearchFranchisesQuery(query);

  useEffect(() => {
    if (searchData.franchises) {
      const activeCount = searchData.franchises.filter(franchise => franchise.status).length;
      const inactiveCount = searchData.franchises.filter(franchise => !franchise.status).length;
      const suspendedCount = searchData.franchises.filter(franchise => franchise.locked).length;

      setActiveFranchises(activeCount);
      setInactiveFranchises(inactiveCount);
      setSuspendedFranchises(suspendedCount);
    }
  }, [searchData]);

  return (
 <div>
  <SubSidebar/>
    <div className="content">
    <SubHeader />
        <div className="main-content">

            <div className="card">
                <h3 style={{borderBottom: '1px solid #0024b3'}}>Dashboard</h3>
                <div className="row" style={{borderBottom: '1px solid #0024b3 ',paddingBottom: '40px'}} >
                    <div className="col-md-4" style={{marginLeft: '0px'}}>
                        <div className="first-section">
                            <div className="uiper">
                                <div className="icon">
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                </div>
                                <div className="content-ment">
                                <h1>{activeFranchises}</h1>
                                    <p>Active Franchise</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="first-section">
                            <div className="uiper" id="halfday">
                                <div className="icon">
                                    <i className="fa fa-user-times" aria-hidden="true"></i>
                                </div>
                                <div className="content-ment">
                                <h1>{inactiveFranchises}</h1>
                                    <p>In-active Franchise</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="first-section">
                            <div className="uiper" id="halfday2">
                                <div className="icon">
                                    <i className="fa fa-user-times" aria-hidden="true"></i>
                                </div>
                                <div className="content-ment">
                                <h1>{suspendedFranchises}</h1>
                                    <p>Suspend Franchise</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
 </div>
 


  );
};

export default SubHome;
