import { configureStore } from "@reduxjs/toolkit";
import authService from "./services/authService";
import categoryService from "./services/categoryService";
import gameHistoryService from "./services/gameHistoryService";
import userService from "./services/userService";
import distributorService from "./services/distributorService";
import authReducer from "./reducers/authReducer";
import globalReducer from "./reducers/globalReducer";
import cartReducer from "./reducers/cartReducer";
import transactionService from "./services/transactionService";
import subdistributionService from "./services/subdistributionService";
import franchiseService from "./services/franchiseService";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import settlementService from "./services/settlementService";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, authReducer);

const Store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [userService.reducerPath]: userService.reducer,
    [categoryService.reducerPath]: categoryService.reducer,
    [gameHistoryService.reducerPath]: gameHistoryService.reducer,
    [transactionService.reducerPath]: transactionService.reducer,
    [distributorService.reducerPath]: distributorService.reducer,
    [subdistributionService.reducerPath]: subdistributionService.reducer,
    [franchiseService.reducerPath]: franchiseService.reducer,
    [settlementService.reducerPath]: settlementService.reducer,
    authReducer: persistedReducer,
    global: globalReducer,
    cart:cartReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      [categoryService.middleware,
        userService.middleware,
      gameHistoryService.middleware,
      transactionService.middleware,
      distributorService.middleware,
      subdistributionService.middleware,
      franchiseService.middleware,
      settlementService.middleware,
      authService.middleware]
    
    ),
});
export default Store;

export let persistor = persistStore(Store);