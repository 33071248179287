import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const transactionService = createApi({
    reducerPath: 'transactions',
    tagTypes: 'transactions',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://realdeal.org.in/api/api/', // Change this URL to your backend API URL
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.authReducer?.adminToken;
            headers.set('authorization', token ? `Bearer ${token}` : '');
            return headers;
        }
    }),
    endpoints: (builder) => {
        return {
            createTransaction: builder.mutation({
                query: (data) => ({
                    url: '/transactions',
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: ['transactions']
            }),
            getAllTransactions: builder.query({
                query: () => '/transactions',
                providesTags: ['transactions']
            }),
            updateTransaction: builder.mutation({
                query: ({ id, ...data }) => ({
                    url: `/transactions/${id}`,
                    method: 'PUT',
                    body: data
                }),
                invalidatesTags: ['transactions']
            }),
            deleteTransaction: builder.mutation({
                query: (id) => ({
                    url: `/transactions/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['transactions']
            }),
            getTransactionsByUserIdAndDateRange: builder.query({
                query: ({ userId, fromDate, toDate,sender, receiver,type }) => ({
                    url: '/transactions/filter',
                    method: 'POST',
                    body: { userId, fromDate, toDate,sender, receiver,type }
                }),
                providesTags: ['transactions']
            }),
            getTransactionsByFranchiseId: builder.query({
                query: (franchiseId) => ({
                    url: `/transactions/franchise/${franchiseId}`,
                    method: 'GET'
                }),
                providesTags: ['transactions']
            }),
            getTransactionsByFranchiseIdAndType: builder.query({
                query: ({ franchiseId, transactionType }) => ({
                    url: `/transactions/filter/byFranchiseAndType`,
                    method: 'POST',
                    body: { franchiseId, transactionType }
                }),
                providesTags: ['transactions']
            }),
          
        }
    }
});

export const {
    useCreateTransactionMutation,
    useGetAllTransactionsQuery,
    useUpdateTransactionMutation,
    useDeleteTransactionMutation,
    useGetTransactionsByUserIdAndDateRangeQuery,
    useGetTransactionsByFranchiseIdQuery,
    useGetTransactionsByFranchiseIdAndTypeQuery,
} = transactionService;

export default transactionService;
