import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import logo from "../../../assets/images/log.png"
import { useSelector } from "react-redux";
import { useSearchUsersQuery } from "../../../store/services/userService";

const Home = () => {
    const [activeFranchises, setActiveFranchises] = useState(0);
    const [inactiveFranchises, setInactiveFranchises] = useState(0);
    const [suspendedFranchises, setSuspendedFranchises] = useState(0);
    const adminId = useSelector((state) => state.authReducer.adminId);
    const [query, setQuery] = useState({franchiseId: adminId});
  
    const { data: searchData = [], isLoading: isSearchLoading, refetch: refetchSearchData } = useSearchUsersQuery(query);
  
    useEffect(() => {
      if (searchData.users) {
        const activeCount = searchData.users.filter(franchise => franchise.status).length;
        const inactiveCount = searchData.users.filter(franchise => !franchise.status).length;
        const suspendedCount = searchData.users.filter(franchise => franchise.locked).length;
  
        setActiveFranchises(activeCount);
        setInactiveFranchises(inactiveCount);
        setSuspendedFranchises(suspendedCount);
      }
    }, [searchData]);
  
  return (
 <div>
  <Sidebar/>
    <div className="content">
       <Header/>

       <div className="mobileheader">
            <div className="row">
                <div className="col-md-4 col-4">
                    <div className="menubar">
                        <i className="fa fa-user-times" aria-hidden="true"></i>
                    </div>
                </div>
                <div className="col-md-4 col-4">
                    <center><img src={logo} alt="" style={{width: '80px'}}/></center>
                </div>
                <div className="col-md-4 col-4">
                    <div className="menubar">
                        <i className="fa fa-user-times" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
       </div>

        <div className="main-content">

            <div className="card">
                <h3 style={{borderBottom: '1px solid #0024b3'}}>Dashboard</h3>
                <div className="row" style={{borderBottom: '1px solid #0024b3 ',paddingBottom: '40px'}} >
                    <div className="col-md-4 col-12" style={{marginLeft: '0px'}}>
                        <div className="first-section">
                            <div className="uiper">
                                <div className="icon">
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                </div>
                                <div className="content-ment">
                                    <h1>{activeFranchises}</h1>
                                    <p>Active Players</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="first-section">
                            <div className="uiper" id="halfday">
                                <div className="icon">
                                    <i className="fa fa-user-times" aria-hidden="true"></i>
                                </div>
                                <div className="content-ment">
                                    <h1>{inactiveFranchises}</h1>
                                    <p>In-active Players</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="first-section">
                            <div className="uiper" id="halfday2">
                                <div className="icon">
                                    <i className="fa fa-user-times" aria-hidden="true"></i>
                                </div>
                                <div className="content-ment">
                                    <h1>{suspendedFranchises}</h1>
                                    <p>Suspend Players</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
 </div>
 


  );
};

export default Home;
