import React, { useState, useEffect } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { useAdminUpdateUserPassMutation, useGetUsersByFranchiseIdQuery, useUpdateAdminUserPassMutation, useUpdateUserCoinsMutation } from '../../../store/services/userService';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../components/Header/Header';
import { setAdminCoins } from '../../../store/reducers/authReducer';


const ChangeUsersPass = () => {
  const adminId = useSelector((state) => state.authReducer.adminId);
  const [selectedUser, setSelectedUser] = useState('');
  
  const [transactionPassword, setTransactionPassword] = useState('');
  
  const dispatch = useDispatch();
  const { data: usersData, isLoading, isError } = useGetUsersByFranchiseIdQuery(adminId);
  const [updateUserCoins,{  isSuccess, data: mutationData, error: mutationError  }] = useUpdateAdminUserPassMutation();

  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      await updateUserCoins({
        franchiseId: adminId,
        userId: selectedUser,
        
        newPassword:transactionPassword
      });

      setSelectedUser('');
      
      setTransactionPassword('');
      
      
    } catch (error) {
      console.error('Error updating user coins:', error);
      // Access mutation error (assuming error.data exists)
      const errorMessage = error?.data?.message || 'Error updating user coins';
      alert(errorMessage); // Set error message
    }
  };

  useEffect(() => {
    if (isSuccess && mutationData?.resp === true) {
      alert('Password Change successful');
    } else if (mutationError) {
      console.log(mutationError,"error we got")
      const errorMessage = mutationError?.data?.error || 'Error updating user coins';
      alert(errorMessage);
    }
  }, [isSuccess, mutationData, mutationError, dispatch]);

  

  return (
    <div>
      <Sidebar />
      <div class="content">
      <Header/>
        <div class="main-content">
          <div class="card">
            <h3 style={{ borderBottom: '1px solid #0024b3' }}>
              Password Adjustment
            </h3>
            <div
              class="view"
              style={{
                paddingBottom: '40px',
                backgroundColor: '#e6ebff',
                paddingTop: '20px',
                marginTop: '20px',
              }}
            >
              <div class="row">
                <div class="col-md-4">
                  <div class="form">
                    <label for="">Type:</label>
                    <select
                      name=""
                      id=""
                      class="form-control"
                      disabled
                      value="User"
                    >
                      <option value="User">User</option>
                    </select>
                  </div>
                </div>
                </div>
                <div class="row">
                <div class="col-md-4">
                  <div class="form">
                    <label for="">Partner:</label>
                    <select
                      name=""
                      id=""
                      class="form-control"
                      value={selectedUser}
                      onChange={handleUserChange}
                    >
                      <option value="">Select</option>
                      {usersData &&
                        usersData.users.map((user) => (
                          <option key={user._id} value={user._id}>
                            {user.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                </div>
               
                <div class="row">
                <div class="col-md-4">
                  <div class="form">
                    <label for="">New Password:</label>
                    <br />
                    <input
                      type="text"
                      class="dunerder"
                      value={transactionPassword}
                      onChange={(e) => setTransactionPassword(e.target.value)}
                    />
                  </div>
                </div>
                </div>
               
              <div
                class="suber"
                style={{ display: 'flex', marginTop: '20px', marginLeft: '30px' }}
              >
                <div class="first-main">
                  <button id="submit" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
                <div class="first-main">
                  <button id="submit">Clear</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeUsersPass;
