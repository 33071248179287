
import React, { useState } from 'react'
import Sidebar from '../../../components/Sidebar/Sidebar'
import { useSelector } from 'react-redux';
import { useGetAllGameHistoryQuery, useGetGameHistoryByFranchiseIdQuery } from '../../../store/services/gameHistoryService';
import Header from '../../../components/Header/Header';
import AdminHeader from '../../../components/Admin/AdminHeader';
import AdminSidebar from '../../../components/Admin/AdminSidebar';

const GameHistory = () => {
    const [username, setusername] = useState('');
    const [startDate, setStartDate] =  useState(new Date().toISOString().split('T')[0]);;
    const [endDate, setEndDate] =  useState(new Date().toISOString().split('T')[0]);;
    const [dateRange, setDateRange] = useState('');
    const adminId = useSelector((state) => state.authReducer.adminId);
    const adminName = useSelector((state) => state.authReducer.adminName);
    const [ queryParams, setQueryParams ] = useState({
        franchiseId: adminId,
        
        fromDate: '',
        toDate: '',

    });

    const { data: filteredTransactions } = useGetAllGameHistoryQuery(queryParams);
  
    const handleSubmit = async () => {
        let queryParameters = {};
        queryParameters.franchiseId= adminId
         if (dateRange) {
            const today = new Date();
            let fromDate, toDate;

            switch (dateRange) {
                case 'today':
                    fromDate = toDate = today.toISOString().split('T')[0];
                    break;
                case 'yesterday':
                    const yesterday = new Date(today);
                    yesterday.setDate(today.getDate() - 1);
                    fromDate = toDate = yesterday.toISOString().split('T')[0];
                    break;
                case 'this_week':
                    const monday = new Date(today);
                    monday.setDate(today.getDate() - today.getDay() + 1);
                    fromDate = monday.toISOString().split('T')[0];
                    toDate = today.toISOString().split('T')[0];
                    break;
                case 'last_week':
                    const lastMonday = new Date(today);
                    lastMonday.setDate(today.getDate() - today.getDay() - 6);
                    const lastSunday = new Date(today);
                    lastSunday.setDate(today.getDate() - today.getDay());
                    fromDate = lastMonday.toISOString().split('T')[0];
                    toDate = lastSunday.toISOString().split('T')[0];
                    break;
                case 'this_month':
                    fromDate = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
                    toDate = today.toISOString().split('T')[0];
                    break;
                case 'last_month':
                    const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                    const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                    fromDate = firstDayOfLastMonth.toISOString().split('T')[0];
                    toDate = lastDayOfLastMonth.toISOString().split('T')[0];
                    break;
                default:
                    break;
            }
            queryParameters.fromDate = fromDate;
            queryParameters.toDate = toDate;
        }else if (startDate && endDate) {
            queryParameters.fromDate = startDate;
            queryParameters.toDate = endDate;
        } 

        queryParameters.userName = username;
        setQueryParams(queryParameters);

    };

    let totalPlayPoints = 0;
    let totalWinPoints = 0;
    let totalEndPoints = 0;
    if (filteredTransactions && filteredTransactions.userGameHistory) {
        filteredTransactions.userGameHistory.forEach(transaction => {
            totalPlayPoints += transaction.totalPlayPoints || 0;
            totalWinPoints += transaction.totalWinPoints || 0;
            totalEndPoints += transaction.totalEndPoints || 0;
        });
    }


  return (
    <div>
   <AdminSidebar/>
   <div className="content">
   <AdminHeader/>
        <div className="main-content">

        <div className="card" style={{height: '100%'}}>
        <h3 style={{borderBottom: '1px solid #0024b3'}}>Game History</h3>
                <div className="view"
                     style={{paddingBottom: '40px',backgroundColor: '#e6ebff',paddingTop: '20px',marginTop: '20px',}}>
                     <div classNameName="row" style={{display:"flex"}}>
                        {/* <div className="col-md-4">
                            <div className="form">
                                <label for="">Game Name :</label>
                                <select name="" id="" className="form-control" style={{marginTop: '5px'}}>
                                    <option value="">Select</option>
                                    <option value="">Spin Game</option>
                                    <option value="">Yesterday</option>
                                    <option value="">Today</option>
                                </select>
                            </div>
                        </div> */}

                        <div className="col-md-4">
                            <div className="form">
                                <label for="">User Id : </label>
                                <input type="text" value={username} onChange={(e) => setusername(e.target.value)} className="form-control" style={{ marginTop: '5px' }} placeholder="User Name" />
                                
                            </div>
                        </div>
                    </div>
                    <div classNameName="row" style={{display:"flex"}}>
                    <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Start Date: </label>
                                        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="form-control" style={{ marginTop: '5px' }} placeholder="Transaction Password" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">End Date: </label>
                                        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="form-control" style={{ marginTop: '5px' }} placeholder="Transaction Password" />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form">
                                        <label htmlFor="">Date Range :</label>
                                        <select value={dateRange} onChange={(e) => setDateRange(e.target.value)} className="form-control" style={{ marginTop: '5px' }}>
                                            <option value="">Select</option>
                                            <option value="today">Today</option>
                                            <option value="yesterday">Yesterday</option>
                                            <option value="this_week">This Week</option>
                                            <option value="last_week">Last Week</option>
                                            <option value="this_month">This Month</option>
                                            <option value="last_month">Last Month</option>
                                        </select>
                                    </div>
                                </div>
                    </div>
                    <div className="suber" style={{ display: 'flex', marginTop: '20px', marginLeft: '14px' }}>
                                <div className="first-main">
                                    <button id="submit" onClick={handleSubmit}>Submit</button>
                                </div>
                                <div className="first-main">
                                    <button id="submit">Clear</button>
                                </div>
                            </div>
                </div>


            </div>

            <div className="duimn">
            <table>
                            <thead style={{ backgroundColor: '#e6ebff', textAlign: 'center', color: '#000' }}>
                        <th>Start Date: {queryParams.fromDate}</th>
                        <th>End Date: {queryParams.toDate}</th>
                        <th>Play points: {totalPlayPoints}</th>
                        <th>Win points: {totalWinPoints}</th>
                        <th>End points: {totalEndPoints}</th>
                        </thead>
                        </table>
                    </div>
            <div className="duimn">
                        <table>
                            <thead style={{ backgroundColor: '#e6ebff', textAlign: 'center', color: '#0024b3' }}>
                                <tr>
                                    <th>S. No</th>
                                    <th>User Id</th>
                                    
                                    <th>Play Points</th>
                                    <th>Win Points</th>
                                    <th>End Points</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredTransactions?.gameHistory ? (
                                        filteredTransactions.gameHistory.map((transaction, index) => (
                                            <tr key={index} style={{textAlign:"center"}}>
                    <td>{index + 1}</td>
                    <td>{transaction.userId}</td>
                    
                    <td>{transaction.playPoints}</td>
                    <td>{transaction.winPoints}</td>
                    <td>{transaction.endPoints}</td>
                  </tr>
                                        ))
                                    ) : (
                                      <tr style={{ textAlign: "center" }}>
                                      <td colSpan="10"><span>No data available in table</span></td>
                                  </tr>
                                        )}
                            </tbody>
                        </table>
                    </div>
    </div>
    </div>
   
</div>
  )
}

export default GameHistory