
import React, { useState } from 'react'
import Sidebar from '../../../components/Sidebar/Sidebar'
import { useSelector } from 'react-redux';
import { useSearchUsersQuery } from '../../../store/services/userService';
import SubSidebar from '../../../components/SubDistributor/SubSiderbar';
import { useSearchFranchisesQuery } from '../../../store/services/franchiseService';
import SubHeader from '../../../components/SubDistributor/SubHeader';

const SubSearchPage = () => {
    const [searchName, setSearchName] = useState('');
    const [searchEmail, setSearchEmail] = useState('');
    const [searchLockStatus, setSearchLockStatus] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const adminId = useSelector((state) => state.authReducer.adminId);
    const [query, setQuery] = useState({franchiseId: adminId});
  
    const { data: searchData = [], isLoading: isSearchLoading, refetch: refetchSearchData } = useSearchFranchisesQuery(query);

    const handleSearch = async (e) => {
        e.preventDefault();
        // Construct the query object based on the current state
        const newQuery = {
            name: searchName,
            email: searchEmail,
            lockStatus: searchLockStatus,
            status: searchStatus,
            franchiseId: adminId,
        };
        setQuery(newQuery); // Update query state
        console.log("api hit");
    };

   
    const handleClear = () => {
       setSearchName('');
       setSearchEmail('');
       setSearchLockStatus('');
       setSearchStatus('');
    };
    const totalPoints = searchData.franchises?.reduce((sum, user) => sum + user.coins, 0);

    return (
       <div>
         <SubSidebar />
         <div className="content">
         <SubHeader />
           <div className="main-content">
             <div className="card">
               <h3 style={{ borderBottom: '1px solid #0024b3' }}>View Users</h3>
               <div className="view" style={{ paddingBottom: '40px', backgroundColor: '#e6ebff', paddingTop: '20px', marginTop: '20px' }}>
                 <div className="row">
                   <div className="col-md-3" style={{ marginLeft: '0px' }}>
                    <div className="form">
                       <label htmlFor="searchName">Users Name</label>
                       <input type="text" name="searchName" id="searchName" className="form-control" value={searchName} onChange={(e) => setSearchName(e.target.value)} />
                    </div>
                   </div>
                   <div className="col-md-3">
                    <div className="form">
                       <label htmlFor="searchEmail">Email Address:</label>
                       <input type="text" name="searchEmail" id="searchEmail" className="form-control" value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)} />
                    </div>
                   </div>
                   <div className="col-md-3">
                    <div className="form">
                       <label htmlFor="searchLockStatus">Lock Status:</label>
                       <select name="searchLockStatus" id="searchLockStatus" className="form-control" value={searchLockStatus} onChange={(e) => setSearchLockStatus(e.target.value)}>
                         <option value="">Select</option>
                         <option value="true">Locked</option>
                         <option value="false">Unlocked</option>
                       </select>
                    </div>
                   </div>
                   <div className="col-md-3">
                    <div className="form">
                       <label htmlFor="searchStatus">Status:</label>
                       <select name="searchStatus" id="searchStatus" className="form-control" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
                         <option value="">Select</option>
                         <option value="true">Active</option>
                         <option value="false">Inactive</option>
                       </select>
                    </div>
                   </div>
                 </div>
                 <div className="suber" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                   <div className="first-main">
                    <button id="submit" onClick={handleSearch}>Submit</button>
                   </div>
                   <div className="first-main">
                    <button id="reset" onClick={handleClear}>Clear</button>
                   </div>
                 </div>
               </div>

                <div className="duimn">
                    <h4>TOTAL USERS : (<span style={{color: 'red'}}> {searchData.franchises?.length} </span> ) POINTS :<span style={{color: 'green'}}>( {totalPoints}
                            )</span></h4>
                    <table>
                        <tr style={{backgroundColor: '#e6ebff',textAlign: 'center',color: '#0024b3'}}>
                            <th>Player</th>
                            <th>Point</th>
                            <th>Last Login</th>
                            <th>Profit Percentage</th>
                            <th>Lock Status</th>
                            <th>Lock by</th>
                            <th>Status </th>
                            <th>Action</th>
                        </tr>

                        {searchData.franchises?.map(user => (
                                        <tr key={user.id} style={{ textAlign: "center" }}>
                                            <td>{user.name}</td>
                                            <td>{user.coins}</td>
                                            <td>{user.lastLogin}</td>
                                            <td>{user.profitPercentage}%</td>
                                            <td>{user.locked ? 'Locked' : 'Unlocked'}</td>
                                            <td>{user.lockedBy}</td>
                                            <td>{user.status ? 'Active' : 'Inactive'}</td>
                                            <td className="firstman">TRANSFER POINTS</td>
                                        </tr>
                                    ))}
                      

                    </table>

                </div>
            </div>
        </div>

    </div>


</div>
  )
}

export default SubSearchPage
